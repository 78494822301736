/* eslint-disable no-param-reassign */
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ModalsState} from '../../types/state'
import {NameSpace} from "../../constants/api-constants";

const initialState: ModalsState = {
  loginModal: false,
  sendRecoveryCodeModal: false,
  recoveryPasswordModal: false,
  recoverPasswordSuccessModal: false,
  registrationModal: false,
  infoModal: false,
  infoModalText: '',
  infoModalTitle: ''
}

export const modalsData = createSlice({
  name: NameSpace.Modals,
  initialState,
  reducers: {
    changeLoginModal: (state, action: PayloadAction<{loginModal: boolean}>) => {
      const {loginModal} = action.payload
      state.loginModal = loginModal
    },
    changeRegistrationModal: (state, action: PayloadAction<{registrationModal: boolean}>) => {
      const {registrationModal} = action.payload
      state.registrationModal = registrationModal
    },
    changeSendRecoveryCodeModal: (
      state,
      action: PayloadAction<{sendRecoveryCodeModal: boolean}>,
    ) => {
      const {sendRecoveryCodeModal} = action.payload
      state.sendRecoveryCodeModal = sendRecoveryCodeModal
    },
    changeRecoveryPasswordModal: (
      state,
      action: PayloadAction<{recoveryPasswordModal: boolean}>,
    ) => {
      const {recoveryPasswordModal} = action.payload
      state.recoveryPasswordModal = recoveryPasswordModal
    },
    changeRecoveryPasswordSuccessModal: (
      state,
      action: PayloadAction<{recoveryPasswordSuccessModal: boolean}>,
    ) => {
      const {recoveryPasswordSuccessModal} = action.payload
      state.recoverPasswordSuccessModal = recoveryPasswordSuccessModal
    },
    changeInfoModal: (
      state,
      action: PayloadAction<{infoModal: boolean, infoModalText: string, infoModalTitle: string}>,
    ) => {
      state.infoModal = action.payload.infoModal
      state.infoModalText = action.payload.infoModalText
      state.infoModalTitle = action.payload.infoModalTitle
    }
  },
})

export const {
  changeLoginModal,
  changeSendRecoveryCodeModal,
  changeRegistrationModal,
  changeRecoveryPasswordModal,
  changeRecoveryPasswordSuccessModal,
  changeInfoModal
} = modalsData.actions
