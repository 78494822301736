import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import {useAppDispatch, useAppSelector} from '../../../core/hooks'
import {getSendRecoveryCodeModalStatus} from '../../../core/store/modals-data/selectors'
import {
  changeRecoveryPasswordModal,
  changeSendRecoveryCodeModal,
} from '../../../core/store/modals-data/modals-data'
import {sendRecoveryCode} from '../../../core/store/api-actions'
import {sendRecoveryCodeFormValidationSchema} from '../../../core/utils/form-validate-helpers'
import {
  getSendRecoveryCodeErrors,
  getSendRecoveryCodeRequestStatus,
  getSendRecoveryCodeStatus,
} from '../../../core/store/send-recovery-code-data/selectors'
import {SendRequestStatus, sendErrorText} from '../../../core/constants/common'

function ModalSendRecoveryCode() {
  const dispatch = useAppDispatch()
  const [requestError, setRequestError] = useState<string>('')
  const isModalOpen = useAppSelector(getSendRecoveryCodeModalStatus)
  const sendRecoveryCodeErrors = useAppSelector(getSendRecoveryCodeErrors) || {}
  const sendRecoveryCodeStatus = useAppSelector(getSendRecoveryCodeStatus)
  const sendRecoveryCodeRequestStatus = useAppSelector(getSendRecoveryCodeRequestStatus)

  const handleModalClose = () => {
    dispatch(changeSendRecoveryCodeModal({sendRecoveryCodeModal: false}))
  }

  // eslint-disable-next-line
  const onSubmit = async (values: any) => {
    dispatch(sendRecoveryCode(values))
  }

  useEffect(() => {
    if (sendRecoveryCodeRequestStatus === SendRequestStatus.Success && sendRecoveryCodeStatus) {
      dispatch(changeSendRecoveryCodeModal({sendRecoveryCodeModal: false}))
      dispatch(changeRecoveryPasswordModal({recoveryPasswordModal: true}))
    }
  }, [sendRecoveryCodeStatus, sendRecoveryCodeRequestStatus, dispatch])

  useEffect(() => {
    if (sendRecoveryCodeRequestStatus === SendRequestStatus.UnSuccess) {
      setRequestError(sendErrorText)
    }
  }, [sendRecoveryCodeRequestStatus])

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: sendRecoveryCodeFormValidationSchema,
    onSubmit,
  })

  return (
    <div className={`modal ${isModalOpen ? '' : 'hidden'}`}>
      <div className="modal__form">
        <form className="form" onSubmit={formik.handleSubmit}>
          <ul
            className={`form__errors  ${(requestError === '' && Object.values(sendRecoveryCodeErrors).length === 0) ? 'hidden' : ''}`}>
            <li className={`form__error ${requestError === '' ? 'hidden' : ''}`}>{requestError}</li>
            {Object.values(sendRecoveryCodeErrors).map((error) => (
              <li className="form__error" key={error}>
                {error}
              </li>
            ))}
          </ul>
          <p className="form__title">Восстановление пароля</p>
          <p className="modal__text">Введите email, который вы использовали при регистрации, мы отправим вам письмо</p>
          <div className="form__wrap">
            <label className="form__label">
              <span className="form__text">E-mail</span>
              <div className="form__content">
                <input
                  className={`form__input ${formik.errors.email ? 'form__input--error' : ''}`}
                  type="text"
                  placeholder="Введите почту"
                  id="email"
                  name="email"
                  onChange={(e) => {
                    formik.handleChange(e)
                  }}
                  value={formik.values.email}
                />
                <span className="form__warning">{formik.errors.email}</span>
              </div>
            </label>
          </div>
          <button className="button form__button form__button--big">Восстановить</button>
          <a className="modal__close" onClick={handleModalClose}>
            <svg className="modal__icon" width="25" height="25">
              <use xlinkHref="images/sprite.svg#close"/>
            </svg>
          </a>
        </form>
      </div>
    </div>
  )
}

export default ModalSendRecoveryCode
