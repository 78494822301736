import React from 'react'
import {useAppDispatch, useAppSelector} from '../../../core/hooks'
import {getRecoveryPasswordSuccessModalStatus} from '../../../core/store/modals-data/selectors'
import {
  changeLoginModal,
  changeRecoveryPasswordSuccessModal,
} from '../../../core/store/modals-data/modals-data'

function ModalRecoverySuccess() {
  const dispatch = useAppDispatch()
  const isModalOpen = useAppSelector(getRecoveryPasswordSuccessModalStatus)

  const handleModalClose = () => {
    dispatch(changeRecoveryPasswordSuccessModal({recoveryPasswordSuccessModal: false}))
  }

  const onClick = () => {
    dispatch(changeRecoveryPasswordSuccessModal({recoveryPasswordSuccessModal: false}))
    dispatch(changeLoginModal({loginModal: true}))
  }

  return (
    <div className={`modal ${isModalOpen ? '' : 'hidden'}`}>
      <div className="modal__form">
        <div className="form">
          <p className="form__title">Вы успешно восстановили пароль.</p>
          <button className="button form__button form__button--big" onClick={onClick}>Восстановить</button>
        </div>
        <a className="modal__close" onClick={handleModalClose}>
          <svg className="modal__icon" width="25" height="25">
            <use xlinkHref="images/sprite.svg#close"/>
          </svg>
        </a>
      </div>
    </div>
  )
}

export default ModalRecoverySuccess
