import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import {getLoginModalStatus} from "../../../core/store/modals-data/selectors";
import {
  changeLoginModal,
  changeRegistrationModal,
  changeSendRecoveryCodeModal
} from "../../../core/store/modals-data/modals-data";
import {useFormik} from "formik";
import {LoginFormValues} from "../../../core/types/form";
import {loginFormValidationSchema} from "../../../core/utils/form-validate-helpers";
import {login} from "../../../core/store/api-actions";
import {
  getAuthorizationErrors,
  getAuthorizationSendStatus,
  getAuthorizationStatus
} from "../../../core/store/authorization-data/selectors";
import {sendErrorText, SendRequestStatus} from "../../../core/constants/common";
import {resetAuthorizationErrors} from "../../../core/store/authorization-data/authorization-data";

const ModalLogin = () => {
  const dispatch = useAppDispatch()
  const [requestError, setRequestError] = useState<string>('')
  const loginErrors = useAppSelector(getAuthorizationErrors) || {}
  const loginSendStatus = useAppSelector(getAuthorizationSendStatus)
  const loginStatus = useAppSelector(getAuthorizationStatus)

  useEffect(() => {
    if (loginStatus) {
      dispatch(changeLoginModal({loginModal: false}))
    }
  }, [loginStatus, dispatch])

  useEffect(() => {
    if (loginSendStatus === SendRequestStatus.UnSuccess) {
      setRequestError(sendErrorText)
    }
  }, [loginSendStatus])
  const isModalOpen = useAppSelector(getLoginModalStatus)
  const handleModalClose = () => {
    dispatch(changeLoginModal({loginModal: false}))
  }

  const onSubmit = async (values: LoginFormValues) => {
    dispatch(login(values))
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: 0,
    } as LoginFormValues,
    validationSchema: loginFormValidationSchema,
    onSubmit,
  })

  const handleRegistrationModalOpen = () => {
    dispatch(changeLoginModal({loginModal: false}))
    dispatch(changeRegistrationModal({registrationModal: true}))
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(resetAuthorizationErrors())
    formik.handleChange(e)
  }

  const handleSendRecoveryCodeModalOpen = () => {
    dispatch(changeLoginModal({loginModal: false}))
    dispatch(changeSendRecoveryCodeModal({sendRecoveryCodeModal: true}))
  }

  const [showPassword, setShowPassword] = useState(false)

  return (
    <div className={`modal ${isModalOpen ? '' : 'hidden'}`}>
      <div className="modal__form">
        <form className="form" onSubmit={formik.handleSubmit}>
          <ul
            className={`form__errors  ${(requestError === '' && Object.values(loginErrors).length === 0) ? 'hidden' : ''}`}>
            <li className={`form__error ${requestError === '' ? 'hidden' : ''}`}>{requestError}</li>
            {Object.values(loginErrors).map((error) => (
              <li className="form__error" key={error}>
                {error}
              </li>
            ))}
          </ul>
          <p className="form__title">Вход</p>
          <div className="form__wrap">
            <ul className="form__warnins">
              <li className="form__warning">{formik.errors.password}</li>
            </ul>
            <label className="form__label">
              <span className="form__text">E-mail</span>
              <div className="form__content">
                <input
                  id="email"
                  name="email"
                  className={`form__input ${formik.errors.email ? 'form__input--error' : ''}`}
                  type="text"
                  placeholder="Введите почту"
                  onChange={(e) => {
                    handleInputChange(e)
                  }}
                />
                <span className="form__warning">{formik.errors.email}</span>
              </div>
            </label>
            <label className="form__label form__label--password">
              <p className="form__text">Введите пароль</p>
              <div className="form__content">
                <input
                  id="password"
                  className={`form__input ${formik.errors.password ? 'form__input--error' : ''}`}
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  placeholder="Введите ваш пароль"
                  onChange={(e) => {
                    handleInputChange(e)
                  }}
                />
                <span className="form__warning">{formik.errors.password}</span>
              </div>
              <button
                className={`form__control ${showPassword ? 'form__control--view' : ''}`}
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              />
            </label>
          </div>
          <div className="form__block">
            <label className="check form__check">
              <input
                id="remember"
                name="remember"
                type="checkbox"
                className="check__input"
                onChange={(e) => {
                  handleInputChange(e)
                }}
              />
              <span className="check__indicator">
                <span className="check__check"></span>
              </span>
              <span className="check__text">Запомнить меня</span>
            </label>
            <button type="button" className="modal__recovery" onClick={handleSendRecoveryCodeModalOpen}>Забыли пароль?</button>
          </div>
          <div className="form__wrap form__wrap--15">
            <button className="button form__button" type="submit">Войти</button>
            <a className="form__link" onClick={handleRegistrationModalOpen}>Зарегистрироваться</a>
          </div>
          <a className="modal__close" onClick={handleModalClose}>
            <svg className="modal__icon" width="25" height="25">
              <use xlinkHref="images/sprite.svg#close"/>
            </svg>
          </a>
        </form>
      </div>
    </div>
  );
};

export default ModalLogin;
