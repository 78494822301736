import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {fetchCalendarEvents} from '../api-actions';
import {NameSpace} from "../../constants/api-constants";
import {CalendarEventsState} from "../../types/state";
import {Option} from "../../types/common";

const initialState: CalendarEventsState = {
  isCalendarEventsLoading: false,
  isCalendarEventsError: false,
  calendarEvents: [],
  filterSportStatus: {
    value: 'all',
    label: 'Все виды спорта'
  },
  filterDateStatus: null,
  filterCategoryStatus: {
    value: 'all',
    label: 'Все'
  }
};

export const calendarEventsData = createSlice({
  name: NameSpace.CalendarEvents,
  initialState,
  reducers: {
    filteringCalendarEventsBySport: (state, action: PayloadAction<{ filterSportStatus: Option }>) => {
      const {filterSportStatus} = action.payload;
      state.filterSportStatus = filterSportStatus;
    },
    filteringCalendarEventsByDate: (state, action: PayloadAction<{ filterDateStatus: string | null }>) => {
      const {filterDateStatus} = action.payload;
      state.filterDateStatus = filterDateStatus
    },
    filteringCalendarEventsByCategory: (state, action: PayloadAction<{ filterCategoryStatus: Option }>) => {
      const {filterCategoryStatus} = action.payload;
      state.filterCategoryStatus = filterCategoryStatus;
    },
    resetCalendarEventsFilters: (state) => {
      state.filterSportStatus = initialState.filterSportStatus;
      state.filterDateStatus = initialState.filterDateStatus
      state.filterCategoryStatus = initialState.filterCategoryStatus
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCalendarEvents.pending, (state) => {
        state.isCalendarEventsLoading = true;
      })
      .addCase(fetchCalendarEvents.fulfilled, (state, action) => {
        state.calendarEvents = action.payload.items;
        state.isCalendarEventsLoading = false;
      })
      .addCase(fetchCalendarEvents.rejected, (state) => {
        state.isCalendarEventsError = true;
        state.isCalendarEventsLoading = false;
      })
  }
});

export const {
  filteringCalendarEventsBySport,
  filteringCalendarEventsByDate,
  filteringCalendarEventsByCategory,
  resetCalendarEventsFilters
} = calendarEventsData.actions;
