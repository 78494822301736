import {Subscription} from "../../api";
import {PostsTypeTypes, SendVoteType} from "../types/common";

export const POSTS_PER_PAGE = 7;

export const SPORTS_PER_LIST = 7;

export const ALBUMS_PER_PAGE = 15;

export const VIDEOS_PER_PAGE = 15;

export const PHOTOS_PER_PAGE = 18;

export const EVENTS_PER_PAGE = 15;

export const COMPETITIONS_PER_PAGE = 15;

export const MATCHES_PER_PAGE = 16;

export const PAGE_SIZE = 0;

export const PAGE = 1;

export const PLAYLISTS_PER_PAGE = 15;

export enum SendRequestStatus {
  Success = "SUCCESS",
  UnSuccess = "UN_SUCCESS",
  Pending = "PENDING",
  Normal = "NORMAL",
}

export const PostsType: PostsTypeTypes = {
  Posts: {
    value: 'posts',
    label: 'Новости'
  },
  Events: {
    value: 'events',
    label: 'Мероприятия'
  },
  Matches: {
    value: 'matches',
    label: 'Матчи'
  },
}

export const initialFormState: Subscription = {
  email: "",
};

export const initialVoteFormState: SendVoteType = {
  vote_id: null,
  option_id: null,
  ip: '',
};

export const districts = [
  { value: "all", label: "Все районы" },
  { value: "admiralteysky", label: "Адмиралтейский" },
  { value: "vasileostrovsky", label: "Василеостровский" },
  { value: "vyborgsky", label: "Выборгский" },
  { value: "kalininsky", label: "Калининский" },
  { value: "kirovsky", label: "Кировский" },
  { value: "kolpinsky", label: "Колпинский" },
  { value: "krasnogvardeysky", label: "Красногвардейский" },
  { value: "krasnoselsky", label: "Красносельский" },
  { value: "kronshtadtsky", label: "Кронштадтский" },
  { value: "kurortny", label: "Курортный" },
  { value: "moskovsky", label: "Московский" },
  { value: "nevsky", label: "Невский" },
  { value: "petrogradsky", label: "Петроградский" },
  { value: "petrodvortsovy", label: "Петродворцовый" },
  { value: "primorsky", label: "Приморский" },
  { value: "pushkinsky", label: "Пушкинский" },
  { value: "frunzensky", label: "Фрунзенский" },
  { value: "centralny", label: "Центральный" }
];

export const logos = [
  'images/sprite.svg#logo-old',
  'images/sprite.svg#logo-new',
];

export const sendErrorText =
  'Сервер не отвечает. Пожалуйста, попробуйте позже или проверьте ваше интернет-соединение.'


