import React, {useEffect} from 'react';
import ModalLogin from "../modal-login/modal-login";
import ModalRegistration from "../modal-registration/modal-registration";
import WelcomeModal from "../welcome-modal/welcome-modal";
import {useAppSelector} from "../../../core/hooks";
import {
  getInfoModalStatus,
  getLoginModalStatus,
  getRecoveryPasswordModalStatus,
  getRecoveryPasswordSuccessModalStatus,
  getRegistrationModalStatus,
  getSendRecoveryCodeModalStatus
} from "../../../core/store/modals-data/selectors";
import {isSafari} from "../../../core/utils/common-helpers";
import ModalSendRecoveryCode from "../modal-send-recovery-code/modal-send-recovery-code";
import ModalRecoveryPassword from "../modal-recovery-password/modal-recovery-password";
import ModalRecoverySuccess from "../modal-recovery-success/modal-recovery-success";
import ModalInfo from "../modal-info/modal-info";

function Modals() {
  const isLoginModalOpen = useAppSelector(getLoginModalStatus)
  const isRegistrationModalOpen = useAppSelector(getRegistrationModalStatus)
  const isInfoModalOpen = useAppSelector(getInfoModalStatus)
  const isRecoveryPasswordModalOpen = useAppSelector(getRecoveryPasswordModalStatus)
  const isRecoverySuccessModalOpen = useAppSelector(getRecoveryPasswordSuccessModalStatus)
  const isSendRecoveryCodeModalOpen = useAppSelector(getSendRecoveryCodeModalStatus)
  const isModalOpen = isLoginModalOpen || isRegistrationModalOpen || isInfoModalOpen || isRecoveryPasswordModalOpen || isRecoverySuccessModalOpen || isSendRecoveryCodeModalOpen
  useEffect(() => {
    const addClass = () => {
      if (isSafari()) {
        document.body.classList.toggle('fixed-safari', isModalOpen)
        document.documentElement.classList.toggle('fixed-safari', isModalOpen)
      } else {
        document.body.classList.toggle('fixed', isModalOpen)
      }
    }
    addClass()
    return () => {
      addClass()
    }
  }, [isModalOpen])

  return (
    <>
      <WelcomeModal/>
      <ModalLogin/>
      <ModalRegistration/>
      <ModalSendRecoveryCode/>
      <ModalRecoveryPassword/>
      <ModalRecoverySuccess/>
      <ModalInfo/>
    </>
  );
}

export default Modals;
