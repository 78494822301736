import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import {useAppDispatch, useAppSelector} from '../../../core/hooks'
import {getRecoveryPasswordModalStatus} from '../../../core/store/modals-data/selectors'
import {
  changeRecoveryPasswordModal,
  changeRecoveryPasswordSuccessModal,
} from '../../../core/store/modals-data/modals-data'
import {recoveryPassword} from '../../../core/store/api-actions'
import {recoveryPasswordValidationSchema} from '../../../core/utils/form-validate-helpers'
import {SendRequestStatus, sendErrorText} from '../../../core/constants/common'
import {
  getRecoveryPasswordErrors,
  getRecoveryPasswordRequestStatus,
  getRecoveryPasswordStatus,
  getRecoveryPasswordText,
} from '../../../core/store/recovery-password-data/selectors'
import {getSendRecoveryCodeUserId} from '../../../core/store/send-recovery-code-data/selectors'

function ModalRecoveryPassword() {
  const dispatch = useAppDispatch()
  const [firstShowPassword, setFirstShowPassword] = useState(false)
  const [secondShowPassword, setSecondShowPassword] = useState(false)
  const [requestError, setRequestError] = useState<string>('')
  const [recoveryText, setRecoveryText] = useState<string | undefined>(undefined)
  const isModalOpen = useAppSelector(getRecoveryPasswordModalStatus)
  const recoveryPasswordErrors = useAppSelector(getRecoveryPasswordErrors) || {}
  const recoveryPasswordStatus = useAppSelector(getRecoveryPasswordStatus)
  const recoveryPasswordRequestStatus = useAppSelector(getRecoveryPasswordRequestStatus)
  const recoveryPasswordUserId = useAppSelector(getSendRecoveryCodeUserId)
  const recoveryPasswordText = useAppSelector(getRecoveryPasswordText)

  const handleModalClose = () => {
    dispatch(changeRecoveryPasswordModal({recoveryPasswordModal: false}))
  }

  // eslint-disable-next-line
  const onSubmit = async (values: any) => {
    dispatch(recoveryPassword(values))
  }

  useEffect(() => {
    const successCodeSend =
      recoveryPasswordRequestStatus === SendRequestStatus.Success &&
      recoveryPasswordStatus &&
      recoveryPasswordUserId !== undefined
    if (successCodeSend) {
      dispatch(changeRecoveryPasswordModal({recoveryPasswordModal: false}))
      dispatch(changeRecoveryPasswordSuccessModal({recoveryPasswordSuccessModal: true}))
    }
  }, [recoveryPasswordStatus, recoveryPasswordRequestStatus, recoveryPasswordUserId, dispatch])

  useEffect(() => {
    if (recoveryPasswordRequestStatus === SendRequestStatus.UnSuccess) {
      setRequestError(sendErrorText)
    }
  }, [recoveryPasswordRequestStatus])

  const formik = useFormik({
    initialValues: {
      id: '',
      code: '',
      password: '',
      passwordRepeat: '',
    },
    validationSchema: recoveryPasswordValidationSchema,
    onSubmit,
  })

  useEffect(() => {
    formik.setFieldValue('id', recoveryPasswordUserId)
    // eslint-disable-next-line
  }, [recoveryPasswordUserId])

  useEffect(() => {
    setRecoveryText(recoveryPasswordText)
  }, [recoveryPasswordText])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e)
  }

  return (
    <div className={`modal ${isModalOpen ? '' : 'hidden'}`}>
      <div className="modal__form">
        <form className="form" onSubmit={formik.handleSubmit}>
          <ul
            className={`form__errors  ${(requestError === '' && Object.values(recoveryPasswordErrors).length === 0) ? 'hidden' : ''}`}>
            <li className={`form__error ${requestError === '' ? 'hidden' : ''}`}>{requestError}</li>
            <li className={`form__error ${recoveryText === '' ? 'hidden' : ''}`}>{recoveryText}</li>
            {Object.values(recoveryPasswordErrors).map((error) => (
              <li className="form__error" key={error}>
                {error}
              </li>
            ))}
          </ul>
          <p className="form__title">Восстановление пароля пользователя с идентификатором {formik.values.id}</p>
          <div className="form__wrap">
            <label className="form__label">
              <span className="form__text">Код из email</span>
              <div className="form__content">
                <input
                  className={`form__input ${formik.errors.code ? 'form__input--error' : ''}`}
                  type="text"
                  placeholder="Введите код из email"
                  id="code"
                  name="code"
                  onChange={(e) => {
                    formik.handleChange(e)
                  }}
                  value={formik.values.code}
                />
                <span className="form__warning">{formik.errors.code}</span>
              </div>
            </label>
            <label className="form__label form__label--password">
              <p className="form__text">Введите пароль</p>
              <div className="form__content">
                <input
                  id="password"
                  className={`form__input ${formik.errors.password ? 'form__input--error' : ''}`}
                  type={firstShowPassword ? 'text' : 'password'}
                  name="password"
                  placeholder="Придумайте пароль"
                  onChange={handleInputChange}
                  value={formik.values.password}
                />
                <span className="form__warning">{formik.errors.password}</span>
              </div>
              <button
                className={`form__control ${firstShowPassword ? 'form__control--view' : ''}`}
                type="button"
                onClick={() => setFirstShowPassword(!firstShowPassword)}
              />
            </label>
            <label className="form__label form__label--password">
              <p className="form__text">Повторите пароль</p>
              <div className="form__content">
                <input
                  id="passwordRepeat"
                  className={`form__input ${formik.errors.passwordRepeat ? 'form__input--error' : ''}`}
                  type={secondShowPassword ? 'text' : 'password'}
                  name="passwordRepeat"
                  placeholder="Повторите пароль"
                  value={formik.values.passwordRepeat}
                  onChange={handleInputChange}
                />
                <span className="form__warning">{formik.errors.passwordRepeat}</span>
              </div>
              <button
                className={`form__control ${secondShowPassword ? 'form__control--view' : ''}`}
                type="button"
                onClick={() => setSecondShowPassword(!secondShowPassword)}
              />
            </label>
          </div>
          <button className="button form__button form__button--big">Восстановить</button>
        </form>
        <a className="modal__close" onClick={handleModalClose}>
          <svg className="modal__icon" width="25" height="25">
            <use xlinkHref="images/sprite.svg#close"/>
          </svg>
        </a>
      </div>
    </div>
  )
}

export default ModalRecoveryPassword
