import React from "react";
import FilterCalendarItem from "../../smart/filter-calendar-item/filter-calendar-item";

function FilterCalendar() {
  return (
    <ul className="filter filter--6">
      <FilterCalendarItem/>
    </ul>
  );
}

export default FilterCalendar;
