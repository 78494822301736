import {NameSpace} from "../../constants/api-constants";
import {State} from "../../types/state";


export const getRecoveryPasswordRequestStatus = (state: State): string =>
  state[NameSpace.RecoveryPassword].recoveryPasswordRequestStatus
export const getRecoveryPasswordStatus = (state: State): boolean | undefined =>
  state[NameSpace.RecoveryPassword].recoveryPasswordStatus
export const getRecoveryPasswordText = (state: State): string | undefined =>
  state[NameSpace.RecoveryPassword].recoveryPasswordText
export const getRecoveryPasswordErrors = (state: State): object | undefined =>
  state[NameSpace.RecoveryPassword].errors
