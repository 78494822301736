import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {AuthorizationState} from '../../types/state'
import {SendRequestStatus} from '../../constants/common'
import {login, registration} from '../api-actions'
import {NameSpace} from "../../constants/api-constants";

const initialState: AuthorizationState = {
  sendStatus: SendRequestStatus.Normal,
  authorizationStatus: undefined,
  token: undefined,
  userId: undefined,
  userRole: undefined,
  errors: undefined,
  registrationText: '',
  remember: 0,
}

export const authorizationData = createSlice({
  name: NameSpace.Authorization,
  initialState,
  reducers: {
    setLogin: (state, action: PayloadAction<{ token: string; userId: string; userRole: string }>) => {
      state.token = action.payload.token
      state.userId = action.payload.userId
      state.userRole = action.payload.userRole
      state.sendStatus = SendRequestStatus.Success
      state.authorizationStatus = true
    },
    removeLogin: (state) => {
      state.token = initialState.token
      state.userId = initialState.userId
      state.userRole = initialState.userRole
      state.sendStatus = initialState.sendStatus
      state.authorizationStatus = initialState.authorizationStatus
      state.remember = initialState.remember
      localStorage.removeItem('token')
      localStorage.removeItem('userId')
      localStorage.removeItem('userRole')
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('userId')
      sessionStorage.removeItem('userRole')
    },
    resetAuthorizationErrors: (state) => {
      state.errors = initialState.errors
    },
  },
  extraReducers(builder) {
    builder
      .addCase(login.pending, (state) => {
        state.sendStatus = SendRequestStatus.Pending
      })
      .addCase(login.fulfilled, (state, action) => {
        state.sendStatus = SendRequestStatus.Success
        state.token = action.payload.response.token
        state.errors = action.payload.response.errors
        state.remember = action.payload.remember
        state.authorizationStatus = action.payload.response.success
        if (action.payload.response.user_id) {
          state.userId = action.payload.response.user_id.toString()
        }
        if (action.payload.response.role) {
          state.userRole = action.payload.response.role.toString()
        }
        if (state.token && state.userId && state.userRole !== undefined) {
          if (state.remember) {
            localStorage.setItem('token', state.token)
            localStorage.setItem('userId', state.userId.toString())
            localStorage.setItem('userRole', state.userRole.toString())
          } else {
            sessionStorage.setItem('token', state.token)
            sessionStorage.setItem('userId', state.userId.toString())
            sessionStorage.setItem('userRole', state.userRole.toString())
          }
        }
      })
      .addCase(login.rejected, (state) => {
        state.sendStatus = SendRequestStatus.UnSuccess
      })
      .addCase(registration.pending, (state) => {
        state.sendStatus = SendRequestStatus.Pending
      })
      .addCase(registration.fulfilled, (state, action) => {
        state.sendStatus = SendRequestStatus.Success
        state.errors = action.payload.errors
        state.registrationText = action.payload.text
      })
      .addCase(registration.rejected, (state) => {
        state.sendStatus = SendRequestStatus.UnSuccess
      })
  },
})

export const {setLogin, removeLogin, resetAuthorizationErrors} = authorizationData.actions
