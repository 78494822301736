import {State} from "../../types/state";
import {NameSpace} from "../../constants/api-constants";

export const getLoginModalStatus = (state: State): boolean =>
  state[NameSpace.Modals].loginModal
export const getSendRecoveryCodeModalStatus = (state: State): boolean =>
  state[NameSpace.Modals].sendRecoveryCodeModal
export const getRecoveryPasswordModalStatus = (state: State): boolean =>
  state[NameSpace.Modals].recoveryPasswordModal
export const getRecoveryPasswordSuccessModalStatus = (state: State): boolean =>
  state[NameSpace.Modals].recoverPasswordSuccessModal
export const getInfoModalStatus = (state: State): boolean =>
  state[NameSpace.Modals].infoModal
export const getInfoModalText = (state: State): string =>
  state[NameSpace.Modals].infoModalText
export const getInfoModalTitle = (state: State): string =>
  state[NameSpace.Modals].infoModalTitle
export const getRegistrationModalStatus = (state: State): boolean => state[NameSpace.Modals].registrationModal
