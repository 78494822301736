import {createSlice} from '@reduxjs/toolkit';
import {fetchCalendarCategories} from '../api-actions';
import {NameSpace} from "../../constants/api-constants";
import {CalendarCategoriesState} from "../../types/state";

const initialState: CalendarCategoriesState = {
  isCalendarCategoriesLoading: false,
  isCalendarCategoriesError: false,
  calendarCategories: [],
};

export const calendarCategoriesData = createSlice({
  name: NameSpace.CalendarCategories,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCalendarCategories.pending, (state) => {
        state.isCalendarCategoriesLoading = true;
      })
      .addCase(fetchCalendarCategories.fulfilled, (state, action) => {
        state.calendarCategories = action.payload.items;
        state.isCalendarCategoriesLoading = false;
      })
      .addCase(fetchCalendarCategories.rejected, (state) => {
        state.isCalendarCategoriesError = true;
        state.isCalendarCategoriesLoading = false;
      })
  }
});
