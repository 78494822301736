import {Area} from "react-easy-crop";

export function isSafari(): boolean {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function range(start: number, end: number, step: number = 1): number[] {
  const result = [];
  for (let i = start; i < end; i += step) {
    result.push(i);
  }
  return result;
}

export const highlightSearchQuery = (text: string, searchQuery?: string) => {
  if (!searchQuery || !text) return text;
  const escapedSearchQuery = searchQuery.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  const regex = new RegExp(`(${escapedSearchQuery})`, 'gi');
  return text.replace(regex, `<span class="highlight">$1</span>`);
};

export const isReactPlayerSupported = (url: string): boolean => {
  return (
    url.includes('youtube.com') ||
    url.includes('youtu.be') ||
    url.includes('vimeo.com') ||
    url.includes('twitch.tv') ||
    url.includes('facebook.com')
  );
};

export function hexToRgba(hex: string | undefined, opacity: number | undefined): string {
  if (typeof hex !== 'string' || hex === '' || !/^#?[0-9A-Fa-f]{6}$/.test(hex)) {
    throw new Error('Invalid hex color format. Expected a 6-digit hex color.');
  }

  if (opacity === undefined || opacity < 0 || opacity > 1) {
    throw new Error('Opacity must be a number between 0 and 1');
  }

  hex = hex.replace('#', '');

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export const getCroppedImg = (
  imageSrc: string,
  crop: Area,
  outputType: 'base64' | 'file',
): Promise<string | File> => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.src = imageSrc
    image.onload = () => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      if (!ctx) {
        reject(new Error('Failed to get canvas context'))
        return
      }
      canvas.width = crop.width
      canvas.height = crop.height
      ctx.drawImage(image, crop.x, crop.y, crop.width, crop.height, 0, 0, crop.width, crop.height)
      if (outputType === 'base64') {
        const base64Image = canvas.toDataURL('image/png')
        resolve(base64Image)
      } else {
        canvas.toBlob((blob) => {
          if (blob) {
            const file = new File([blob], 'cropped_image.png', {type: 'image/png'})
            resolve(file)
          } else {
            reject(new Error('Failed to create Blob'))
          }
        }, 'image/png')
      }
    }
    image.onerror = () => {
      reject(new Error('Failed to load image'))
    }
  })
}
