import React, {useEffect, useRef, useState} from "react";
import "./../../../assets/css/custom-calendar.css";
import CalendarHeader from "../../ordinary/calendar-header/calendar-header";
import CalendarMain from "../../ordinary/calendar-main/calendar-main";
import {useAppSelector} from "../../../core/hooks";
import {getCalendarEventsFilterDate} from "../../../core/store/calendar-events-data/selectors";

function Calendar() {
  // eslint-disable-next-line
  const calendarRef = useRef<any>(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const filterDateStatus = useAppSelector(getCalendarEventsFilterDate)

  useEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    if (!calendarApi) return;

    Promise.resolve().then(() => {
      if (filterDateStatus === null) {
        calendarApi.gotoDate(new Date());
      } else {
        const date = new Date(filterDateStatus);
        if (!isNaN(date.getTime())) {
          calendarApi.gotoDate(date);
        }
      }
      setCurrentDate(calendarApi.currentData.currentDate);
    });
  }, [filterDateStatus, calendarRef]);

  const handleChangeDate = (type: string, date?: Date) => {
    const calendarApi = calendarRef.current.getApi();
    if (type === 'changeMonth' && date) {
      calendarApi.gotoDate(date);
    } else if (type === 'prevMonth') {
      calendarApi.prev();
    } else if (type === 'nextMonth') {
      calendarApi.next();
    }
    setCurrentDate(calendarApi.currentData.currentDate);
  }

  return (
    <div className="calendar">
      <CalendarHeader
        currentDate={currentDate}
        onChangeDate={handleChangeDate}
      />
      <CalendarMain calendarRef={calendarRef}/>
    </div>
  );
}

export default Calendar;
