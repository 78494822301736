import React from "react";
import {BounceLoader} from "react-spinners";

function Loader({hidden, color}: { hidden?: boolean, color?: string }) {
  return (
    <div className={`loader ${hidden ? 'hidden' : ''}`}>
      <BounceLoader color={color || "#0B4C89"}/>
    </div>
  );
}

export default Loader;
