import {combineReducers} from '@reduxjs/toolkit';
import {NameSpace} from "../constants/api-constants";
import {postsData} from './posts-data/posts-data';
import {albumsData} from "./albums-data/albums-data";
import {videosData} from "./videos-data/videos-data";
import {partnersData} from "./partners-data/partners-data";
import {bannersData} from "./banners-data/banners-data";
import {subscriptionData} from "./subscriptions-data/subscriptions-data";
import {sportsData} from "./sports-data/sports-data";
import {eventPostsData} from './events-data/events-data';
import {defaultPostsData} from "./default-posts-data/default-posts-data";
import {pagesData} from "./pages-data/pages-data";
import {competitionsData} from './competitions-data/competitions-data';
import {competitionsTablesData} from './competitions-tables-data/competitions-tables-data';
import {seasonsData} from "./seasons-data/seasons-data";
import {votesData} from "./votes-data/votes-data";
import {competitionsMatchesData} from './competitions-matches-data/competitions-matches-data';
import {searchesData} from "./searches-data/searches-data";
import {organizationsData} from "./organizations-data/organizations-data";
import {socialsData} from "./socials-data/socials-data";
import {matchPostsData} from './match-posts-data/match-posts-data'
import {widgetTablesData} from "./widget-tables-data/widget-tables-data";
import {videoPlayListsData} from './video-playlists-data/video-playlists-data'
import {calendarEventsData} from "./calendar-events-data/calendar-events-data";
import {calendarCategoriesData} from "./calendar-categories-data/calendar-categories-data";
import {modalsData} from "./modals-data/modals-data";
import {authorizationData} from "./authorization-data/authorization-data";
import {usersData} from "./users-data/users-data";
import sendRecoveryCodeData from "./send-recovery-code-data/send-recovery-code-data";
import recoveryPasswordData from "./recovery-password-data/recovery-password-data";
import vkAuthUrlData from "./vk-auth-url-data/vk-auth-url-data";
import vkLinkData from "./vk-link-data/vk-link-data";
import {editProfileData} from "./edit-profile-data/edit-profile-data";

export const rootReducer = combineReducers({
  [NameSpace.Posts]: postsData.reducer,
  [NameSpace.DefaultPosts]: defaultPostsData.reducer,
  [NameSpace.Partners]: partnersData.reducer,
  [NameSpace.Socials]: socialsData.reducer,
  [NameSpace.Pages]: pagesData.reducer,
  [NameSpace.Subscriptions]: subscriptionData.reducer,
  [NameSpace.Albums]: albumsData.reducer,
  [NameSpace.Organizations]: organizationsData.reducer,
  [NameSpace.Videos]: videosData.reducer,
  [NameSpace.Banners]: bannersData.reducer,
  [NameSpace.Sports]: sportsData.reducer,
  [NameSpace.Seasons]: seasonsData.reducer,
  [NameSpace.Votes]: votesData.reducer,
  [NameSpace.Searches]: searchesData.reducer,
  [NameSpace.EventPosts]:eventPostsData.reducer,
  [NameSpace.Competitions]:competitionsData.reducer,
  [NameSpace.CompetitionsTables]:competitionsTablesData.reducer,
  [NameSpace.CompetitionsMatches]:competitionsMatchesData.reducer,
  [NameSpace.WidgetTables]:widgetTablesData.reducer,
  [NameSpace.MatchPosts]:matchPostsData.reducer,
  [NameSpace.VideoPlaylists]:videoPlayListsData.reducer,
  [NameSpace.CalendarEvents]:calendarEventsData.reducer,
  [NameSpace.CalendarCategories]:calendarCategoriesData.reducer,
  [NameSpace.CompetitionsTables]: competitionsTablesData.reducer,
  [NameSpace.CompetitionsMatches]: competitionsMatchesData.reducer,
  [NameSpace.WidgetTables]: widgetTablesData.reducer,
  [NameSpace.MatchPosts]: matchPostsData.reducer,
  [NameSpace.VideoPlaylists]: videoPlayListsData.reducer,
  [NameSpace.Modals]: modalsData.reducer,
  [NameSpace.Authorization]: authorizationData.reducer,
  [NameSpace.Users]: usersData.reducer,
  [NameSpace.SendRecoveryCode]: sendRecoveryCodeData.reducer,
  [NameSpace.RecoveryPassword]: recoveryPasswordData.reducer,
  [NameSpace.VkAuthUrl]: vkAuthUrlData.reducer,
  [NameSpace.VkLink]: vkLinkData.reducer,
  [NameSpace.EditProfile]: editProfileData.reducer
});
