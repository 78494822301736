import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import {
  getInfoModalStatus,
  getInfoModalText, getInfoModalTitle
} from "../../../core/store/modals-data/selectors";
import {changeInfoModal} from "../../../core/store/modals-data/modals-data";
import React from "react";

function ModalInfo() {
  const dispatch = useAppDispatch()
  const isModalOpen = useAppSelector(getInfoModalStatus)
  const infoModalText = useAppSelector(getInfoModalText)
  const infoModalTitle = useAppSelector(getInfoModalTitle)

  const handleModalClose = () => {
    dispatch(changeInfoModal({infoModal: false, infoModalText: '', infoModalTitle: ''}))
  }

  return (
    <div className={`modal ${isModalOpen ? '' : 'hidden'}`}>
      <div className="modal__form">
        <div className="form">
          <p className="form__title">{infoModalTitle}</p>
          <p className="form__text">{infoModalText}</p>
          <button className="button form__button form__button--big" onClick={handleModalClose}>Ок</button>
        </div>
        <a className="modal__close" onClick={handleModalClose}>
          <svg className="modal__icon" width="25" height="25">
            <use xlinkHref="images/sprite.svg#close"/>
          </svg>
        </a>
      </div>
    </div>
  )
}

export default ModalInfo
