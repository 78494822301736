import React from "react";
import {filterMonths} from "../../../core/constants/filters";
import FilterCalendar from "../filter-calendar/filter-calendar";

function CalendarHeader({currentDate, onChangeDate}: {
  currentDate: Date;
  onChangeDate: (type: string, date?: Date) => void;
}) {
  const currentYear = currentDate.getFullYear();
  const currentMonth = filterMonths[currentDate.getMonth()];

  return (
    <div className="calendar__top">
      <div className="calendar__left">
        <div className="calendar__arrows">
          <a className="calendar__arrow" onClick={() => onChangeDate('prevMonth')}>
            <svg className="calendar__icon" width="10" height="20">
              <use xlinkHref="images/sprite.svg#arrow-white"/>
            </svg>
          </a>
          <a className="calendar__arrow calendar__arrow--right" onClick={() => onChangeDate('nextMonth')}>
            <svg className="calendar__icon" width="10" height="20">
              <use xlinkHref="images/sprite.svg#arrow-white"/>
            </svg>
          </a>
        </div>
        <p className="calendar__date">{currentMonth} {currentYear}</p>
      </div>
      <FilterCalendar/>
    </div>
  );
}

export default CalendarHeader;
