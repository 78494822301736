import React from "react";
import {Route, Routes} from "react-router-dom";
import Main from "../../pages/main/main";
import {RouteType} from "../types/app-route";
import Error from "../../pages/error/error";
import {AppRoute} from "../constants/routes";
import AboutUs from "../../pages/about-us/about-us";
import NewsPage from "../../pages/news-page/news-page";
import NewPage from "../../pages/new-page/new-page";
import CalendarPage from "../../pages/calendar-page/calendar-page";
import CompetitionNewsPage from "../../pages/competition-news-page/competition-news-page";
import CompetitionCalendar from "../../components/smart/competition-calendar/competition-calendar";
import CompetitionPage from "../../pages/competition-page/competition-page";
import CompetitionTable from "../../components/smart/competition-table/competition-table";
import Competitions from "../../pages/competitions/competitions";
import AlbumsPage from "../../pages/albums-page/albums-page";
import AlbumPage from "../../pages/album-page/album-page";
import VideosPage from "../../pages/videos-page/videos-page";
import SchoolsPage from "../../pages/schools-page/schools-page";
import VideoPage from "../../pages/video-page/video-page";
import SearchPostsPage from "../../pages/search-posts-page/search-posts-page";
import SearchEventsPage from "../../pages/search-events-page/search-events-page";
import SearchMatchesPage from "../../pages/search-matches-page/search-matches-page";
import StaticPage from "../../pages/static-page/static-page";
import VideoPlaylistsPage from '../../pages/video-playlists-page/video-playlists-page'
import VideoPlaylistPage from '../../pages/video-playlist-page/video-playlist-page'
import SportPage from '../../pages/sport-page/sport-page'
import PersonalEditPage from "../../pages/personal-edit-page/personal-edit-page";
import PersonalInfoPage from "../../pages/personal-info-page/personal-info-page";

function RoutesComponent() {
  const createElementForRoute = (route: RouteType) => {
    switch (route) {
      case AppRoute.Main:
        return <Main/>;
      case AppRoute.AboutUs:
        return <AboutUs/>;
      case AppRoute.NewsPage:
        return <NewsPage/>;
      case AppRoute.NewPage:
        return <NewPage/>;
      case AppRoute.Calendar:
        return <CalendarPage/>;
      case AppRoute.Competitions:
        return <Competitions/>;
      case AppRoute.CompetitionTable:
        return <CompetitionTable />;
      case AppRoute.CompetitionPage:
        return <CompetitionPage />;
      case AppRoute.CompetitionCalendar:
        return <CompetitionCalendar />;
      case AppRoute.CompetitionNewsPage:
        return <CompetitionNewsPage />;
      case AppRoute.Albums:
        return <AlbumsPage/>;
      case AppRoute.Album:
        return <AlbumPage/>;
      case AppRoute.Schools:
        return <SchoolsPage/>;
      case AppRoute.Videos:
        return <VideosPage/>;
      case AppRoute.Video:
        return <VideoPage/>;
      case AppRoute.AboutStaticPage:
        return <AboutUs/>;
      case AppRoute.StaticPage:
        return <StaticPage/>;
      case AppRoute.SearchPostsPage:
        return <SearchPostsPage/>;
      case AppRoute.SearchEventsPage:
        return <SearchEventsPage/>;
      case AppRoute.SearchMatchesPage:
        return <SearchMatchesPage/>;
      case AppRoute.VideoPlaylistsPage:
        return <VideoPlaylistsPage/>;
      case AppRoute.VideoPlaylistPage:
        return <VideoPlaylistPage/>;
      case AppRoute.PersonalEditPage:
        return <PersonalEditPage/>;
      case AppRoute.PersonalInfoPage:
        return <PersonalInfoPage/>;
      case AppRoute.SportPage:
        return <SportPage/>;
      default:
        return <Error/>;
    }
  };
  return (
    <Routes>
      {Object.values(AppRoute).map((route) => (
        <Route
          key={route.url}
          path={route.url}
          element={createElementForRoute(route)}
        />
      ))}
    </Routes>
  );
}

export default RoutesComponent;
