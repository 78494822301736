/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import EventModal from "../event-modal/event-modal";
import {DateClickArg} from "@fullcalendar/interaction";
import EventsList from "../events-list/events-list";
import interactionPlugin from "@fullcalendar/interaction";
import {GroupedEvent} from "../../../core/types/common";
import {groupingEvents} from "../../../core/utils/array-prepare-helpers";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {fetchCalendarEvents} from "../../../core/store/api-actions";
import {
  getCalendarEvents,
  getCalendarEventsFilterCategory,
  getCalendarEventsFilterSport
} from "../../../core/store/calendar-events-data/selectors";
import {CalendarEvent} from "../../../api";

function CalendarMain({calendarRef}: { calendarRef: any; }) {
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | undefined>(undefined);
  const [isModalOpen, setModalOpen] = useState(false);
  const [dayEvents, setDayEvents] = useState<CalendarEvent[] | undefined>([]);
  const [isDayEventsOpen, setDayEventsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const events = useAppSelector(getCalendarEvents);
  const filterSportStatus = useAppSelector(getCalendarEventsFilterSport);
  const filterCategoryStatus = useAppSelector(getCalendarEventsFilterCategory);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCalendarEvents({}));
  }, [dispatch]);

  useEffect(() => {
    const sportKindId =
      filterSportStatus.value !== 'all' ? Number(filterSportStatus.value) : undefined;
    const categoryId =
      filterCategoryStatus.value !== 'all' ? Number(filterCategoryStatus.value) : undefined;

    dispatch(fetchCalendarEvents({ sportKindId, categoryId }));
  }, [filterSportStatus, filterCategoryStatus, dispatch]);

  const groupedEvents: GroupedEvent[] = groupingEvents(events)

  const handleEventClick = (event: CalendarEvent) => {
    setSelectedEvent(event);
    if (event) {
      setModalOpen(true);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedEvent(undefined);
  };

  const handleDateClick = (arg: DateClickArg) => {
    const eventsForDay = events?.filter(event => event.start_dt === arg.dateStr);
    setDayEvents(eventsForDay);
    setSelectedDate(arg.dateStr);
    if (eventsForDay && eventsForDay.length > 0) {
      setDayEventsOpen(true);
    }
  };

  return (
    <>
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView='dayGridMonth'
        events={groupedEvents}
        headerToolbar={false}
        locale={'ru'}
        height={'auto'}
        contentHeight={'auto'}
        firstDay={1}
        dateClick={handleDateClick}
        dayHeaderFormat={{weekday: 'long'}}
        dayCellContent={(arg) => {
          const dayNumber = arg.date.getDate();
          const dayName = arg.date.toLocaleDateString('ru-RU', {weekday: 'long'});

          return (
            <div className="day">
              <div className="day__name">{dayName}</div>
              <div className="day__date">{dayNumber}</div>
            </div>
          );
        }}
      />
      <EventsList
        events={dayEvents}
        selectedDate={selectedDate}
        onEventClick={handleEventClick}
        onClose={() => setDayEventsOpen(false)}
        showModal={isDayEventsOpen}
      />
      <EventModal
        event={selectedEvent}
        onClose={closeModal}
        showModal={isModalOpen}
      />
    </>
  );
}

export default CalendarMain;
