import React, {useEffect} from "react";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {getCalendarCategories} from "../../../core/store/calendar-categories-data/selectors";
import CalendarButton from "../../ordinary/calendar-button/calendar-button";
import {fetchCalendarCategories} from "../../../core/store/api-actions";
import {filteringCalendarEventsByCategory} from "../../../core/store/calendar-events-data/calendar-events-data";
import {getCalendarEventsFilterCategory} from "../../../core/store/calendar-events-data/selectors";

function CalendarButtons() {
  const dispatch = useAppDispatch()
  const calendarCategories = useAppSelector(getCalendarCategories)
  const filterCategoryStatus = useAppSelector(getCalendarEventsFilterCategory)

  useEffect(() => {
    dispatch(fetchCalendarCategories())
  }, [dispatch]);

  const onClick= () => {
    dispatch(filteringCalendarEventsByCategory({filterCategoryStatus: {value: 'all', label: 'Все'}}))
  }

  return (
    <ul className="links">
      {calendarCategories?.map((calendarCategory) => (
        <CalendarButton key={calendarCategory.category_id} calendarCategory={calendarCategory}/>
      ))}
      <li className={`links__item ${filterCategoryStatus.value === 'all' ? 'links__item--active' : ''}`}>
        <button className="links__link" type="button" onClick={onClick}>Все</button>
      </li>
    </ul>
  );
}

export default CalendarButtons;
