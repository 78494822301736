import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import {getRegistrationModalStatus} from "../../../core/store/modals-data/selectors";
import {changeInfoModal, changeRegistrationModal} from "../../../core/store/modals-data/modals-data";
import {useFormik} from "formik";
import {RegistrationFormValues} from "../../../core/types/form";
import {registrationFormValidationSchema} from "../../../core/utils/form-validate-helpers";
import {registration} from "../../../core/store/api-actions";
import {resetAuthorizationErrors} from "../../../core/store/authorization-data/authorization-data";
import {
  getAuthorizationErrors,
  getAuthorizationSendStatus,
  getRegistrationText
} from "../../../core/store/authorization-data/selectors";
import {sendErrorText, SendRequestStatus} from "../../../core/constants/common";

const ModalRegistration = () => {
  const dispatch = useAppDispatch()
  const isModalOpen = useAppSelector(getRegistrationModalStatus)
  const [firstShowPassword, setFirstShowPassword] = useState(false)
  const [secondShowPassword, setSecondShowPassword] = useState(false)
  const registrationErrors = useAppSelector(getAuthorizationErrors) || {}
  const registrationText = useAppSelector(getRegistrationText)
  const registrationSendStatus = useAppSelector(getAuthorizationSendStatus)
  const [requestError, setRequestError] = useState<string>('')

  useEffect(() => {
    if (registrationSendStatus === SendRequestStatus.Success && registrationText) {
      dispatch(changeRegistrationModal({registrationModal: false}))
      dispatch(changeInfoModal({infoModal: true, infoModalText: registrationText, infoModalTitle: 'Регистрация'}))
    }
  }, [registrationText, dispatch, registrationSendStatus])

  useEffect(() => {
    if (registrationSendStatus === SendRequestStatus.UnSuccess) {
      setRequestError(sendErrorText)
    }
  }, [registrationSendStatus])

  const handleModalClose = () => {
    dispatch(changeRegistrationModal({registrationModal: false}))
  }

  const onSubmit = async (values: RegistrationFormValues) => {
    dispatch(registration(values))
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      passwordRepeat: '',
    } as RegistrationFormValues,
    validationSchema: registrationFormValidationSchema,
    onSubmit,
  })

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(resetAuthorizationErrors())
    formik.handleChange(e)
  }

  return (
    <div className={`modal ${isModalOpen ? '' : 'hidden'}`}>
      <div className="modal__form" onSubmit={e => e.preventDefault()}>
        <form className="form" onSubmit={formik.handleSubmit}>
          <ul
            className={`form__errors  ${(requestError === '' && Object.values(registrationErrors).length === 0) ? 'hidden' : ''}`}>
            <li className={`form__error ${requestError === '' ? 'hidden' : ''}`}>{requestError}</li>
            {Object.values(registrationErrors).map((error) => (
              <li className="form__error" key={error}>
                {error}
              </li>
            ))}
          </ul>
          <p className="form__title">Регистрация</p>
          <div className="form__wrap">
            <label className="form__label">
              <span className="form__text">E-mail</span>
              <div className="form__content">
                <input
                  className={`form__input ${formik.errors.email ? 'form__input--error' : ''}`}
                  type="text"
                  placeholder="Введите почту"
                  id="email"
                  name="email"
                  onChange={handleInputChange}
                  value={formik.values.email}
                />
                <span className="form__warning">{formik.errors.email}</span>
              </div>
            </label>
            <label className="form__label form__label--password">
              <p className="form__text">Введите пароль</p>
              <div className="form__content">
                <input
                  id="password"
                  className={`form__input ${formik.errors.password ? 'form__input--error' : ''}`}
                  type={firstShowPassword ? 'text' : 'password'}
                  name="password"
                  placeholder="Придумайте пароль"
                  onChange={handleInputChange}
                  value={formik.values.password}
                />
                <span className="form__warning">{formik.errors.password}</span>
              </div>
              <button
                className={`form__control ${firstShowPassword ? 'form__control--view' : ''}`}
                type="button"
                onClick={() => setFirstShowPassword(!firstShowPassword)}
              />
            </label>
            <label className="form__label form__label--password">
              <p className="form__text">Повторите пароль</p>
              <div className="form__content">
                <input
                  id="passwordRepeat"
                  className={`form__input ${formik.errors.passwordRepeat ? 'form__input--error' : ''}`}
                  type={secondShowPassword ? 'text' : 'password'}
                  name="passwordRepeat"
                  placeholder="Повторите пароль"
                  value={formik.values.passwordRepeat}
                  onChange={handleInputChange}
                />
                <span className="form__warning">{formik.errors.passwordRepeat}</span>
              </div>
              <button
                className={`form__control ${secondShowPassword ? 'form__control--view' : ''}`}
                type="button"
                onClick={() => setSecondShowPassword(!secondShowPassword)}
              />
            </label>
          </div>
          <button className="button form__button form__button--big">Зарегистрироваться</button>
          <a className="modal__close" onClick={handleModalClose}>
            <svg className="modal__icon" width="25" height="25">
              <use xlinkHref="images/sprite.svg#close"/>
            </svg>
          </a>
        </form>
      </div>
    </div>
  );
};

export default ModalRegistration;
