import {State} from "../../types/state";
import {NameSpace} from "../../constants/api-constants";
import {CalendarEvent} from "../../../api";
import {Option} from "../../types/common";

export const getCalendarEventsLoading = (state: State): boolean => state[NameSpace.CalendarEvents].isCalendarEventsLoading;
export const getCalendarEventsError = (state: State): boolean => state[NameSpace.CalendarEvents].isCalendarEventsError;
export const getCalendarEvents = (state: State): CalendarEvent[] | undefined => state[NameSpace.CalendarEvents].calendarEvents;
export const getCalendarEventsFilterSport = (state: State): Option => state[NameSpace.CalendarEvents].filterSportStatus;
export const getCalendarEventsFilterDate = (state: State): string | null => state[NameSpace.CalendarEvents].filterDateStatus;
export const getCalendarEventsFilterCategory = (state: State): Option => state[NameSpace.CalendarEvents].filterCategoryStatus;
