import React, { useEffect } from "react";
import ReactPlayer from "react-player/youtube";
import ContentPlay from "../../simple/content-play/content-play";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import { getPlayerVideos } from "../../../core/store/videos-data/selectors";
import { isReactPlayerSupported } from "../../../core/utils/common-helpers";
import { fetchVideos } from "../../../core/store/api-actions";
import { ApiVideosGetInPlayerEnum } from "../../../api";

const VideoPlayer = () => {
  const dispatch = useAppDispatch();
  const videos = useAppSelector(getPlayerVideos);

  useEffect(() => {
    dispatch(fetchVideos({ inPlayer: ApiVideosGetInPlayerEnum.NUMBER_1 }));
  }, [dispatch]);

  const video = videos[0] ?? {};
  const videoUrl = video?.url ?? "";
  const videoEmbedUrl = video?.embed ?? "";
  const isSupported = isReactPlayerSupported(videoUrl);

  return (
    <div className={`video-player ${!video ? "hidden" : ""}`}>
      {isSupported ? (
        <ReactPlayer
          light
          url={videoUrl}
          controls
          width="100%"
          playIcon={<ContentPlay />}
          playing
        />
      ) : (
        <iframe
          src={videoEmbedUrl}
          title={video?.title}
          frameBorder="0"
          allowFullScreen
          style={{ width: "100%", height: "350px" }}
          loading="lazy"
        />
      )}
    </div>
  );
};

export default VideoPlayer;
