import React from "react"
import Tags from "../../smart/tags/tags"
import Social from "../../smart/social/social"
import Accessible from "../../smart/accessible/accessible"
import {AppRoute} from "../../../core/constants/routes"
import {Navigations} from "../../../core/constants/navigations"
import {useAppSelector} from "../../../core/hooks"
import {getPagesMainAlias} from "../../../core/store/pages-data/selectors"
import NavItem from "../../ordinary/nav-item/nav-item"
import Profile from "../../ordinary/profile/profile";

function Nav() {
  const mainAlias = useAppSelector(getPagesMainAlias);

  return (
    <div className="nav">
      <ul className="nav__list">
        <NavItem navigation={{
          title: 'Главная',
          url: AppRoute.Main.url,
          icon: 'home'
        }}/>
        <NavItem navigation={{
          title: 'О нас',
          url: `${AppRoute.AboutStaticPages.url}/${mainAlias}`,
          icon: 'person'
        }}/>
        {Navigations.map((navigation) =>
          <NavItem navigation={navigation} key={navigation.icon}/>
        )}
      </ul>
      <div className="nav__footer">
        <Tags className="tags--wrap"/>
        <div className="nav__block">
          <Social/>
          <Accessible/>
        </div>
        <Profile/>
      </div>
    </div>
  )
}

export default Nav
