import React from "react";
import PersonalItem from "../personal-item/personal-item";
import {User} from "../../../api";

function PersonalList({user}: {user: User | null}) {
  return (
    <ul className="personal__list">
      <PersonalItem title="Фамилия:" subtitle={user?.profile?.last_name}/>
      <PersonalItem title="Имя:" subtitle={user?.profile?.first_name}/>
      <PersonalItem title="E-mail:" subtitle={user?.email}/>
    </ul>
  )
}

export default PersonalList;
