import React from "react";
import HtmlRenderer from "../../smart/html-renderer/html-renderer";
import {CalendarEvent} from "../../../api";

const EventModal = ({event, onClose, showModal}: {
  event?: CalendarEvent;
  onClose: () => void;
  showModal: boolean;
}) => {
  return (
    <div className={`modal ${!showModal ? 'hidden' : ''}`}>
      <div className="modal__content modal__content--no-background">
        <div className="event">
          <div className="event__wrap">
            <img className="event__img" src={
              event?.logo && event.logo.includes('logo_placeholder')
                ? '/images/defaults/event.svg'
                : event?.logo}
                 alt={event ? event.title : ''}/>
            <p className="event__date">{event?.start_dt ? new Date(event.start_dt).toLocaleDateString('ru-RU', {
              day: 'numeric',
              month: 'long',
              year: 'numeric'
            }) : ''}</p>
          </div>
          <h2 className="event__title">{event ? event.title : ''}</h2>
          <div className="redactor-styles">
            <HtmlRenderer htmlString={event?.description || "Информация отсутствует"}/>
          </div>
          <a className="button" href={event ? event.link : '#'}>Сайт мероприятия</a>
        </div>
        <a className="modal__close" onClick={onClose}>
          <svg className="modal__icon" width="25" height="25">
            <use xlinkHref="images/sprite.svg#close"/>
          </svg>
        </a>
      </div>
    </div>
  );
};

export default EventModal;
