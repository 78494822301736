import {NameSpace} from "../../constants/api-constants";
import {State} from "../../types/state";


export const getEditProfileRequestStatus = (state: State): string =>
  state[NameSpace.EditProfile].editProfileRequestStatus
export const getEditProfileStatus = (state: State): boolean | undefined =>
  state[NameSpace.EditProfile].editProfileStatus
export const getEditProfileErrors = (state: State): object | undefined =>
  state[NameSpace.EditProfile].errors
