import React from "react";
import Reset from "../reset/reset";
import {ItemsForFilter} from "../../../core/constants/filters";
import SportsFilter from "../sports-filter/sports-filter";
import {ru} from "date-fns/locale";
import DatePicker from "react-datepicker";
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import {getCalendarEventsFilterDate} from "../../../core/store/calendar-events-data/selectors";
import {filteringCalendarEventsByDate} from "../../../core/store/calendar-events-data/calendar-events-data";

function FilterCalendarItem() {
  const filterDateStatus = useAppSelector(getCalendarEventsFilterDate)
  const dispatch = useAppDispatch();

  return (
    <>
      <SportsFilter items={ItemsForFilter.CalendarEvents}/>
      <li className="filter__item">
        <DatePicker
          selected={filterDateStatus ? new Date(filterDateStatus) : null}
          onChange={(date) => {
            if (date) {
              dispatch(filteringCalendarEventsByDate({ filterDateStatus: date.toISOString() }));
            } else {
              dispatch(filteringCalendarEventsByDate({ filterDateStatus: null }));
            }
          }}
          className="date__input"
          placeholderText="мм.гггг"
          dateFormat="MM.yyyy"
          locale={ru}
          showPopperArrow={false}
          showMonthYearPicker
        />
      </li>
      <li className="filter__item">
        <Reset items={ItemsForFilter.CalendarEvents}/>
      </li>
    </>
  );
}

export default FilterCalendarItem;
