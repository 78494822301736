import {createSlice} from '@reduxjs/toolkit'
import {RecoveryPasswordState} from '../../types/state'
import {SendRequestStatus} from '../../constants/common'
import {recoveryPassword} from '../api-actions'
import {NameSpace} from "../../constants/api-constants";

const initialState: RecoveryPasswordState = {
  recoveryPasswordRequestStatus: SendRequestStatus.Normal,
  recoveryPasswordStatus: undefined,
  recoveryPasswordText: undefined,
  errors: undefined,
}

const recoveryPasswordData = createSlice({
  name: NameSpace.RecoveryPassword,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(recoveryPassword.pending, (state) => {
        state.recoveryPasswordRequestStatus = SendRequestStatus.Pending
      })
      .addCase(recoveryPassword.fulfilled, (state, action) => {
        state.recoveryPasswordRequestStatus = SendRequestStatus.Success
        state.recoveryPasswordStatus = action.payload.response.success
        state.recoveryPasswordText = action.payload.response.text
        state.errors = action.payload.response.errors
      })
      .addCase(recoveryPassword.rejected, (state) => {
        state.recoveryPasswordRequestStatus = SendRequestStatus.UnSuccess
      })
  },
})

export default recoveryPasswordData
