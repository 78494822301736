import {createSlice} from "@reduxjs/toolkit";
import {NameSpace} from "../../constants/api-constants";
import {linkVk} from "../api-actions";
import {SendRequestStatus} from "../../constants/common";
import {VkLinkState} from "../../types/state";


const initialState: VkLinkState = {
  getVkLinkRequestStatus: SendRequestStatus.Normal,
  getVkLinkStatus: undefined,
}

const vkLinkData = createSlice({
  name: NameSpace.VkLink,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(linkVk.pending, (state) => {
        state.getVkLinkRequestStatus = SendRequestStatus.Pending
      })
      .addCase(linkVk.fulfilled, (state, action) => {
        state.getVkLinkRequestStatus = SendRequestStatus.Success
        state.getVkLinkStatus = action.payload.response.success
      })
      .addCase(linkVk.rejected, (state) => {
        state.getVkLinkRequestStatus = SendRequestStatus.UnSuccess
      })
  },
})

export default vkLinkData
