import React from 'react';
import Tags from '../../smart/tags/tags';
import Social from '../../smart/social/social';
import Search from "../../smart/form-search/form-search";
import Accessible from "../../smart/accessible/accessible";
import SearchMobile from "../search-mobile/search-mobile";
import LogoProject from "../../ordinary/logo-project/logo-project";
import Burger from "../../smart/burger/burger";
import Nav from "../nav/nav";
import Profile from "../../ordinary/profile/profile";

function Header() {
  return (
    <header className="header">
      <div className="header__container">
        <SearchMobile/>
        <LogoProject className="show"/>
        <Burger/>
        <Nav/>
        <Tags className="hide"/>
        <div className="header__content hide">
          <Search/>
          <Social/>
          <Accessible/>
          <Profile/>
        </div>
      </div>
    </header>
  );
}

export default Header;
