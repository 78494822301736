import React from "react";
import {CalendarCategory} from "../../../api";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {filteringCalendarEventsByCategory} from "../../../core/store/calendar-events-data/calendar-events-data";
import {getCalendarEventsFilterCategory} from "../../../core/store/calendar-events-data/selectors";
import {hexToRgba} from "../../../core/utils/common-helpers";

function CalendarButton({calendarCategory}: {calendarCategory: CalendarCategory}) {
  const dispatch = useAppDispatch()
  const filterCategoryStatus = useAppSelector(getCalendarEventsFilterCategory)
  const rgbaColor = hexToRgba(calendarCategory.color, 0.2)

  const onClick= () => {
    dispatch(filteringCalendarEventsByCategory({filterCategoryStatus: {value: calendarCategory.category_id, label: calendarCategory.title}}))
  }

  return (
    <li className={`links__item ${filterCategoryStatus.value === calendarCategory.category_id ? 'links__item--active' : ''}`}>
      <button
        className="links__link"
        type="button"
        onClick={onClick}
        style={{
          backgroundColor: rgbaColor,
          color: calendarCategory.color
      }}
      >
        {calendarCategory.title}
      </button>
    </li>
  );
}

export default CalendarButton;
