import React, {useState, useCallback} from 'react'
import Cropper, {Area} from 'react-easy-crop'
import {getCroppedImg} from '../../../core/utils/common-helpers'

function ImageCropper({
  image,
  onCropCompleteBase64,
  onCropCompleteFile,
  onCancel,
  aspect = 1,
  outputType,
}: {
  image: string
  onCropCompleteBase64: (croppedImage: string | null) => void
  onCropCompleteFile: (croppedImage: File | null) => void
  onCancel: () => void
  aspect?: number
  outputType: 'base64' | 'file'
}) {
  const [crop, setCrop] = useState<{x: number; y: number}>({x: 0, y: 0})
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null)

  const onCropCompleteHandler = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const handleCrop = async () => {
    if (croppedAreaPixels) {
      let croppedImage
      try {
        croppedImage = await getCroppedImg(image, croppedAreaPixels, outputType)
      } catch (error) {
        return
      }
      if (outputType === 'base64') {
        onCropCompleteBase64(croppedImage as string | null)
      } else {
        onCropCompleteFile(croppedImage as File | null)
      }
    }
  }

  return (
    <>
      <div className="cropper">
        <Cropper
          image={image}
          crop={crop}
          zoom={zoom}
          aspect={aspect}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropCompleteHandler}
        />
        <div className="cropper__buttons">
          <button className="cropper__button button" onClick={handleCrop}>Сохранить</button>
          <button className="cropper__button button" onClick={onCancel}>Закрыть</button>
        </div>
      </div>
    </>
  )
}

export default ImageCropper
