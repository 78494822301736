export enum NameSpace {
  Posts = "POSTS",
  Partners = "PARTNERS",
  Socials = "SOCIALS",
  Subscriptions = "SUBSCRIPTIONS",
  Albums = "ALBUMS",
  Videos = "VIDEOS",
  Organizations = "ORGANIZATIONS",
  Banners = "BANNERS",
  Sports = "SPORTS",
  Seasons = "SEASONS",
  Votes = "VOTES",
  EventPosts = "EVENT_POSTS",
  DefaultPosts = "DEFAULT_POSTS",
  Pages = "PAGES",
  Searches = "SEARCHES",
  Competitions = "COMPETITIONS",
  CompetitionsTables = "COMPETITIONS_TABLES",
  CompetitionsMatches = "COMPETITIONS_MATCHES",
  WidgetTables = "WIDGET_TABLES",
  MatchPosts = "MATCH_POSTS",
  VideoPlaylists = "VIDEO_PLAYLISTS",
  CalendarEvents = "CALENDAR_EVENTS",
  CalendarCategories = "CALENDAR_CATEGORIES",
  Modals = "MODALS",
  Authorization = "AUTHORIZATION",
  Users = "USERS",
  SendRecoveryCode = 'SEND_RECOVERY_CODE',
  RecoveryPassword = 'RECOVERY_PASSWORD',
  VkAuthUrl = 'VK_AUTH_URL',
  VkLink = 'VK_LINK',
  EditProfile = 'EDIT_PROFILE'
}
