import React from "react";

function PersonalItem({title, subtitle}: { title: string, subtitle?: string }) {
  return (
    <li className="personal__item">
      <p className="personal__title">{title}</p>
      <p className="personal__subtitle">{subtitle ? subtitle : <span className="personal__absence">не указано</span>}</p>
    </li>
  )
}

export default PersonalItem;
