import {SendVoteType} from "../types/common";
import * as Yup from 'yup'


const passwordRegExp = /^(?=.*[A-Za-z])(?=.*\d).+$/


export const validateEmail = (email: string) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailRegex.test(email);
};

export function validateVoteForm(formData: SendVoteType, userIpAddress: string | null): { [key: string]: string } {
  const newErrors: { [key: string]: string } = {};
  const allFieldsFilled = Object.values(formData).every((value) => value !== null && value !== '');
  if (!allFieldsFilled) {
    newErrors['choice'] = 'Пожалуйста, выберите хотя бы один вариант';
  }
  if (!userIpAddress) {
    newErrors['ipAddress'] = 'Ошибка при получении IP-адреса, проголосовать не получится';
  }
  return newErrors;
}

export function validateSearchForm(searchString: string): { [key: string]: string } {
  const newErrors: { [key: string]: string } = {};
  const searchStringFilled = searchString !== null && searchString !== '';
  if (!searchStringFilled) {
    newErrors['search'] = 'Поисковой запрос не может быть пустым';
  }
  return newErrors;
}

export const loginFormValidationSchema = Yup.object({
  email: Yup.string().required('Email не может быть пустым').email('Введите корректный email'),
  password: Yup.string().required('Пароль не может быть пустым'),
})

export const registrationFormValidationSchema = Yup.object({
  email: Yup.string().email('Введите корректный email').required('Email не может быть пустым'),
  password: Yup.string()
    .min(8, 'Пароль должен быть больше 8 символов')
    .matches(passwordRegExp, 'Пароль должен включать цифру и только латинские буквы')
    .required('Пароль не может быть пустым'),
  passwordRepeat: Yup.string()
    .oneOf([Yup.ref('password')], 'Пароли должны совпадать')
    .min(8, 'Пароль должен быть больше 8 символов')
    .matches(passwordRegExp, 'Пароль должен включать цифру и только латинские буквы')
    .required('Пароль не может быть пустым'),
})

export const editProfileFormValidationSchema = Yup.object({
  lastName: Yup.string(),
  firstName: Yup.string(),
  email: Yup.string().email('Введите корректный email'),
  password: Yup.string()
    .min(8, 'Пароль должен быть больше 8 символов')
    .matches(passwordRegExp, 'Пароль должен включать цифру и только латинские буквы'),
  passwordRepeat: Yup.string()
    .oneOf([Yup.ref('password')], 'Пароли должны совпадать')
    .min(8, 'Пароль должен быть больше 8 символов')
    .matches(passwordRegExp, 'Пароль должен включать цифру и только латинские буквы')
})

export const sendRecoveryCodeFormValidationSchema = Yup.object({
  email: Yup.string().required('Email не может быть пустым').email('Введите корректный email'),
})

export const recoveryPasswordValidationSchema = Yup.object({
  id: Yup.number().required('Код из email не может быть пустым'),
  code: Yup.string().required('Код из email не может быть пустым'),
  password: Yup.string()
    .min(8, 'Пароль должен быть больше 8 символов')
    .matches(passwordRegExp, 'Пароль должен включать цифру и только латинские буквы')
    .required('Пароль не может быть пустым'),
  passwordRepeat: Yup.string()
    .oneOf([Yup.ref('password')], 'Пароли должны совпадать')
    .min(8, 'Пароль должен быть больше 8 символов')
    .matches(passwordRegExp, 'Пароль должен включать цифру и только латинские буквы')
    .required('Пароль не может быть пустым'),
})

