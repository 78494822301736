/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect} from "react";
import {isSafari} from "../../../core/utils/common-helpers";
import {CalendarEvent} from "../../../api";

const EventsList = ({events, selectedDate, onEventClick, onClose, showModal}: {
  events: CalendarEvent[] | undefined;
  selectedDate: string | null;
  onEventClick: (event: CalendarEvent) => void;
  onClose: () => void;
  showModal: boolean;
}) => {

  const groupedEvents = events?.reduce((acc: { [key: string]: any[] }, event) => {
    const eventType = event.category.title || "Другая категория";
    if (!acc[eventType]) {
      acc[eventType] = [];
    }
    acc[eventType].push(event);
    return acc;
  }, {});

  useEffect(() => {
    const body = document.body;
    const html = document.documentElement;
    const handleBodyClass = () => {
      body.classList.toggle(isSafari() ? 'fixed-safari' : 'fixed', showModal);
      html.classList.toggle('fixed-safari', showModal);
    };
    handleBodyClass();
    return () => {
      handleBodyClass();
    };
  }, [showModal]);

  return (
    <div className={`modal ${!showModal ? 'hidden' : ''}`}>
      <div className="modal__content modal__content--no-background">
        <div className="infos">
          <h3 className="infos__title">
            {selectedDate ? new Date(selectedDate).toLocaleDateString('ru-RU', {
              day: 'numeric',
              month: 'long',
              year: 'numeric'
            }) : ''}
          </h3>
          <ul className="infos__groups">
            {groupedEvents && Object.keys(groupedEvents).map(type => (
              <li className="infos__group" key={type}>
                <h4 className="infos__type" style={{backgroundColor: groupedEvents[type][0].category.color}}>{type}</h4>
                <ul className="infos__events">
                  {groupedEvents[type].map(event => (
                    <li className="infos__event" key={event.title}>
                      <div className="infos__wrap">
                        <p className="infos__text">{event.title}</p>
                        <a className="infos__link" onClick={() => onEventClick(event)}>Подробнее</a>
                      </div>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
        <a className="modal__close" onClick={onClose}>
          <svg className="modal__icon" width="25" height="25">
            <use xlinkHref="images/sprite.svg#close"/>
          </svg>
        </a>
      </div>
    </div>
  );
};

export default EventsList;
