import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import {getUser, getUserError, getUserLoading} from "../../core/store/users-data/selectors";
import {useFormik} from "formik";
import {EditProfileFormValues} from "../../core/types/form";
import {editProfileFormValidationSchema} from "../../core/utils/form-validate-helpers";
import {sendErrorText, SendRequestStatus} from "../../core/constants/common";
import {
  getEditProfileErrors,
  getEditProfileRequestStatus,
  getEditProfileStatus
} from "../../core/store/edit-profile-data/selectors";
import {resetEditProfileErrors} from "../../core/store/edit-profile-data/edit-profile-data";
import {editProfile} from "../../core/store/api-actions";
import Loader from "../../components/simple/loader/loader";
import ImageCropper from "../../components/smart/image-cropper/image-cropper";
import Texts from "../../components/ordinary/texts/texts";
import {getAuthorizationStatus} from "../../core/store/authorization-data/selectors";
import {changeInfoModal} from "../../core/store/modals-data/modals-data";

function PersonalEditPage() {
  const dispatch = useAppDispatch()
  const [firstShowPassword, setFirstShowPassword] = useState(false)
  const [secondShowPassword, setSecondShowPassword] = useState(false)
  const user = useAppSelector(getUser)
  const userLoading = useAppSelector(getUserLoading)
  const userError = useAppSelector(getUserError)
  const loginStatus = useAppSelector(getAuthorizationStatus)
  const storedToken = localStorage.getItem('token') || sessionStorage.getItem('token')
  const editProfileSendStatus = useAppSelector(getEditProfileRequestStatus)
  const editProfileStatus = useAppSelector(getEditProfileStatus)
  const editProfileErrors = useAppSelector(getEditProfileErrors) || {}
  const [requestError, setRequestError] = useState<string>('')
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [croppedImage, setCroppedImage] = useState<string | null>(null)
  const [isCropping, setIsCropping] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0]
    if (file) {
      const img = new Image()
      img.onload = () => {
        if (img.width < 400 || img.height < 400) {
          setError('Размер изображения должен быть не менее 400x400 пикселей')
          setSelectedFile(null)
        } else {
          setError(null)
          setSelectedFile(file)
          setIsCropping(true)
        }
      }
      img.src = URL.createObjectURL(file)
    }
  }

  const handleRemovePhoto = () => {
    setSelectedFile(null)
    setCroppedImage(null)
    formik.setFieldValue('photo', undefined)
  }

  const handleCropComplete = (croppedImgFile: File | null) => {
    setIsCropping(false)
    if (croppedImgFile) {
      setCroppedImage(URL.createObjectURL(croppedImgFile))
      formik.setFieldValue('photo', croppedImgFile)
    }
  }

  useEffect(() => {
    if (editProfileSendStatus === SendRequestStatus.UnSuccess) {
      setRequestError(sendErrorText)
    }
  }, [editProfileSendStatus])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(resetEditProfileErrors())
    formik.handleChange(e)
  }

  const onSubmit = async (values: EditProfileFormValues) => {
    dispatch(editProfile(values))
  }

  const formik = useFormik({
    initialValues: {
      token: '',
      lastName: '',
      firstName: '',
      email: '',
      password: '',
      passwordRepeat: '',
      photo: undefined
    } as EditProfileFormValues,
    validationSchema: editProfileFormValidationSchema,
    onSubmit,
  })

  useEffect(() => {
    if (user) {
      formik.setValues({
        token: `Bearer ${storedToken}`,
        lastName: user.profile?.last_name || '',
        firstName: user.profile?.first_name || '',
        email: user.email || '',
        password: '',
        passwordRepeat: '',
        photo: undefined
      });
    }
  }, [user, storedToken]);

  useEffect(() => {
    if (editProfileSendStatus === SendRequestStatus.Success && editProfileStatus) {
      dispatch(changeInfoModal({infoModal: true, infoModalText: 'Вы успешно отредактировали данные', infoModalTitle: 'Редактирование профиля'}))
    }
  }, [editProfileSendStatus, editProfileStatus]);

  return (
    <>
      <Loader hidden={!userLoading}/>
      <Texts
        texts="Вы не авторизованы"
        hidden={loginStatus}
      />
      <Texts
        texts="Произошла ошибка при получении данных пользователя"
        hidden={!userError}
      />
      <div className={`personal ${userLoading || !loginStatus || userError ? 'hidden' : ''}`}>
        <div className="personal__profile">
          <img className="personal__img"
               src={croppedImage ? croppedImage : user?.profile?._links?.photo?.href?.includes('placeholder_thumb.svg') ? '/images/defaults/person.svg' : user?.profile?._links?.photo?.href}
               width="230"
               height="230"
               alt={`${user?.profile?.last_name} ${user?.profile?.first_name}`}
          />
          <p className="personal__text">Мин. размер фото 400x400</p>
          <div className="personal__wrapper">
            <p className="button personal__button">
              {user?.profile?._links?.photo?.href?.includes("placeholder_thumb.svg")
                ? "загрузить фото"
                : "изменить фото"}
            </p>
            <input className="personal__input" type="file" onChange={handleFileChange}/>
          </div>
          {selectedFile && (
            <button type="button" className="button" onClick={handleRemovePhoto}>Удалить изображение</button>
          )}
        </div>
        <form className="form personal__form" onSubmit={formik.handleSubmit}>
          <div className="form__wrap">
            <ul
              className={`form__errors  ${(requestError === '' && Object.values(editProfileErrors).length === 0) ? 'hidden' : ''}`}>
              <li className={`form__error ${requestError === '' ? 'hidden' : ''}`}>{requestError}</li>
              <li className={`form__error ${error === '' ? 'hidden' : ''}`}>{error}</li>
              {Object.values(editProfileErrors).map((error) => (
                <li className="form__error" key={error}>
                  {error}
                </li>
              ))}
            </ul>
            <label className="form__label">
              <span className="form__text">Фамилия</span>
              <div className="form__content">
                <input
                  id="lastName"
                  className={`form__input ${formik.errors.lastName ? 'form__input--error' : ''}`}
                  type="text"
                  placeholder="Введите фамилию"
                  value={formik.values.lastName}
                  name="lastName"
                  onChange={handleInputChange}
                />
                <span className="form__warning">{formik.errors.lastName}</span>
              </div>
            </label>
            <label className="form__label">
              <span className="form__text">Имя</span>
              <div className="form__content">
                <input
                  id="firstName"
                  className={`form__input ${formik.errors.firstName ? 'form__input--error' : ''}`}
                  type="text"
                  placeholder="Введите имя"
                  value={formik.values.firstName}
                  name="firstName"
                  onChange={handleInputChange}
                />
                <span className="form__warning">{formik.errors.firstName}</span>
              </div>
            </label>
            <label className="form__label">
              <span className="form__text">E-mail</span>
              <div className="form__content">
                <input
                  id="email"
                  className={`form__input ${formik.errors.email ? 'form__input--error' : ''}`}
                  type="text"
                  placeholder="Введите почту"
                  value={formik.values.email}
                  name="email"
                  onChange={handleInputChange}
                />
                <span className="form__warning">{formik.errors.email}</span>
              </div>
            </label>
            <label className="form__label form__label--password">
              <p className="form__text">Введите пароль</p>
              <div className="form__content">
                <input
                  id="password"
                  className={`form__input ${formik.errors.password ? 'form__input--error' : ''}`}
                  type={firstShowPassword ? 'text' : 'password'}
                  name="password"
                  placeholder="Введите ваш пароль"
                  value={formik.values.password}
                  onChange={handleInputChange}
                />
                <span className="form__warning">{formik.errors.password}</span>
              </div>
              <button
                className={`form__control ${firstShowPassword ? 'form__control--view' : ''}`}
                type="button"
                onClick={() => setFirstShowPassword(!firstShowPassword)}
              />
            </label>
            <label className="form__label form__label--password">
              <p className="form__text">Повторите пароль</p>
              <div className="form__content">
                <input
                  id="passwordRepeat"
                  className={`form__input ${formik.errors.passwordRepeat ? 'form__input--error' : ''}`}
                  type={secondShowPassword ? 'text' : 'password'}
                  name="passwordRepeat"
                  placeholder="Введите ваш пароль еще раз"
                  value={formik.values.passwordRepeat}
                  onChange={handleInputChange}
                />
                <span className="form__warning">{formik.errors.passwordRepeat}</span>
              </div>
              <button
                className={`form__control ${secondShowPassword ? 'form__control--view' : ''}`}
                type="button"
                onClick={() => setSecondShowPassword(!secondShowPassword)}
              />
            </label>
          </div>
          <button type="submit" className="button button--personal form__button personal__exit">Сохранить</button>
        </form>
      </div>
      {isCropping && selectedFile && (
        <ImageCropper
          image={URL.createObjectURL(selectedFile)}
          onCropCompleteBase64={() => {
          }}
          onCropCompleteFile={handleCropComplete}
          onCancel={() => setIsCropping(false)}
          outputType="file"
        />
      )}
    </>
  )
}

export default PersonalEditPage;
