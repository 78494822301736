import React from "react";
import Crumbs from "../../components/ordinary/crumbs/crumbs";
import Title from "../../components/ordinary/title/title";
import {AppRoute} from "../../core/constants/routes";
import {useAppSelector} from "../../core/hooks";
import Loader from "../../components/simple/loader/loader";
import Calendar from "../../components/simple/calendar/calendar";
import {getCalendarEventsLoading} from "../../core/store/calendar-events-data/selectors";
import CalendarButtons from "../../components/smart/calendar-buttons/calendar-buttons";

function CalendarPage() {
  const crumbsRoutes = [AppRoute.Main, AppRoute.Calendar];
  const eventsLoading = useAppSelector(getCalendarEventsLoading);

  return (
    <>
      <Loader hidden={!eventsLoading}/>
      <div className={`gap gap--25 ${eventsLoading ? 'hidden' : ''}`}>
        <Crumbs routes={crumbsRoutes}/>
        <div className="gap gap--50">
          <div className="buttons-wrapper">
            <Title title="Календарь мероприятий"/>
            <CalendarButtons/>
          </div>
          <Calendar/>
        </div>
      </div>
    </>
  );
}

export default CalendarPage;
