import {createSlice} from '@reduxjs/toolkit'
import {EditProfileState} from '../../types/state'
import {SendRequestStatus} from '../../constants/common'
import {editProfile} from '../api-actions'
import {NameSpace} from "../../constants/api-constants";

const initialState: EditProfileState = {
  editProfileRequestStatus: SendRequestStatus.Normal,
  editProfileStatus: false,
  errors: undefined,
}

export const editProfileData = createSlice({
  name: NameSpace.EditProfile,
  initialState,
  reducers: {
    resetEditProfileErrors: (state) => {
      state.errors = initialState.errors
    },
  },
  extraReducers(builder) {
    builder
      .addCase(editProfile.pending, (state) => {
        state.editProfileRequestStatus = SendRequestStatus.Pending
      })
      .addCase(editProfile.fulfilled, (state, action) => {
        state.editProfileRequestStatus = SendRequestStatus.Success
        state.editProfileStatus = action.payload.success
        state.errors = action.payload.errors
      })
      .addCase(editProfile.rejected, (state) => {
        state.editProfileRequestStatus = SendRequestStatus.UnSuccess
      })
  },
})

export const {resetEditProfileErrors} = editProfileData.actions
