import {createSlice} from '@reduxjs/toolkit'
import {VkAuthUrlState} from '../../types/state'
import {SendRequestStatus} from '../../constants/common'
import {NameSpace} from "../../constants/api-constants";
import {sendTokenAndGetVkAuthUrl} from "../api-actions";

const initialState: VkAuthUrlState = {
  getVkAuthUrlRequestStatus: SendRequestStatus.Normal,
  getVkAuthUrlStatus: undefined,
  vkAuthUrl: undefined,
}

const vkAuthUrlData = createSlice({
  name: NameSpace.VkAuthUrl,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(sendTokenAndGetVkAuthUrl.pending, (state) => {
        state.getVkAuthUrlRequestStatus = SendRequestStatus.Pending
      })
      .addCase(sendTokenAndGetVkAuthUrl.fulfilled, (state, action) => {
        state.getVkAuthUrlRequestStatus = SendRequestStatus.Success
        state.getVkAuthUrlStatus = action.payload.response.success
        state.vkAuthUrl = action.payload.response.auth_url
      })
      .addCase(sendTokenAndGetVkAuthUrl.rejected, (state) => {
        state.getVkAuthUrlRequestStatus = SendRequestStatus.UnSuccess
      })
  },
})

export default vkAuthUrlData
