import React, {useEffect} from "react";
import PersonalList from "../../components/ordinary/personal-list/personal-list";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {AppRoute} from "../../core/constants/routes";
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import {removeLogin} from "../../core/store/authorization-data/authorization-data";
import {resetUser} from "../../core/store/users-data/users-data";
import {getUser, getUserError, getUserLoading} from "../../core/store/users-data/selectors";
import {getVkAuthUrl} from "../../core/store/vk-auth-url-data/selectors";
import {
  fetchUser,
  linkVk,
  sendTokenAndGetVkAuthUrl
} from "../../core/store/api-actions";
import {
  getVkLinkRequestStatus,
  getVkLinkStatus
} from "../../core/store/vk-link-data/selectors";
import {SendRequestStatus} from "../../core/constants/common";
import {getAuthorizationStatus, getAuthorizationUserId} from "../../core/store/authorization-data/selectors";
import Loader from "../../components/simple/loader/loader";
import Texts from "../../components/ordinary/texts/texts";

function PersonalInfoPage() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const user = useAppSelector(getUser)
  const userLoading = useAppSelector(getUserLoading)
  const userError = useAppSelector(getUserError)
  const loginStatus = useAppSelector(getAuthorizationStatus)
  const storedToken = localStorage.getItem('token') || sessionStorage.getItem('token')
  const userId = useAppSelector(getAuthorizationUserId)
  const vkAuthUrl = useAppSelector(getVkAuthUrl)
  const vkLinkRequestStatus = useAppSelector(getVkLinkRequestStatus)
  const vkLinkStatus = useAppSelector(getVkLinkStatus)
  const [searchParams] = useSearchParams();
  const codeVk = searchParams.get('code');

  useEffect(() => {
    if (codeVk && storedToken) {
      dispatch(linkVk({token: `Bearer ${storedToken}`, code: codeVk}))
    }
  }, [codeVk, storedToken, navigate]);

  const handleRemoveAuthClick = () => {
    dispatch(removeLogin())
    dispatch(resetUser())
    navigate(AppRoute.Main.url)
  }

  useEffect(() => {
    if (vkLinkRequestStatus === SendRequestStatus.Success && vkLinkStatus) {
      dispatch(fetchUser(Number(userId)))
    }
  }, [vkLinkStatus, vkLinkRequestStatus]);

  useEffect(() => {
    if (vkAuthUrl) {
      window.location.href = vkAuthUrl;
    }
  }, [vkAuthUrl]);

  const handleVkConnectClick = () => {
    if (storedToken && !user?.profile?.group_vk_last_check_dt) {
      dispatch(sendTokenAndGetVkAuthUrl({token: `Bearer ${storedToken}`}))
    }
  };

  return (
    <>
      <Loader hidden={!userLoading}/>
      <Texts
        texts="Вы не авторизованы"
        hidden={loginStatus}
      />
      <Texts
        texts="Произошла ошибка при получении данных пользователя"
        hidden={!userError}
      />
      <div className={`personal ${userLoading || !loginStatus || userError ? 'hidden' : ''}`}>
        <div className="personal__profile">
          <img
            className="personal__img"
            src={user?.profile?._links?.photo?.href?.includes('placeholder_thumb.svg') ? '/images/defaults/person.svg' : user?.profile?._links?.photo?.href}
            width="230"
            height="230"
            alt={`${user?.profile?.first_name} ${user?.profile?.last_name}`}
          />
        </div>
        <div className="personal__container">
          <PersonalList user={user}/>
          <div className="personal__wrap">
            <p className="personal__subtitle">Для доступа к уникальному контенту на сайте необходимо:</p>
            <div className="personal__links">
              <button
                className="personal__connect "
                onClick={handleVkConnectClick}
                type="button"
              >
                <span className={`personal__clue ${user?.profile?.group_vk_last_check_dt ? '' : 'hidden'}`}>Вы уже привязали профиль Вконтакте</span>
                <span className="personal__connect">привязать профиль ВК</span>
              </button>
              <p className="personal__and">и</p>
              <a
                className="personal__connect"
                href={user?.profile?.in_group_vk ? undefined : "https://vk.com/vsporte_spb"}
                target={user?.profile?.in_group_vk ? undefined : "_blank"}
                rel="noopener noreferrer"
              >
                <span className={`personal__clue ${user?.profile?.in_group_vk ? '' : 'hidden'}`}>Вы уже подписались на группу Вконтакте</span>
                <span>подписаться на группу ВК</span>
              </a>
            </div>
          </div>
          <div className="personal__buttons">
            <a className="personal__block" href="#">
              <svg className="personal__icon" width="24" height="24">
                <use xlinkHref="images/sprite.svg#redaction"/>
              </svg>
              <Link className="button personal__reduction" to={AppRoute.PersonalEditPage.url}>редактировать</Link>
            </a>
            <button className="button personal__exit" type="button" onClick={handleRemoveAuthClick}>выйти из профиля
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default PersonalInfoPage;
